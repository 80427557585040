import Head from "next/head";

const NextHead: React.FC<{ title: string }> = ({ title }) => {
  const __title: string = title || "Mem Q&A";
  const __description = "Earn to answer or get high quality answers quickly";
  return (
    <Head>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />

      <link rel="icon" href="/favicon.ico" />

      <title>{__title}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content={__description} />

      <meta property="og:title" content={__title} />
      <meta property="og:url" content="https://mem.co" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={__title} />
      <meta property="og:image" content="/mem_social_banner.png" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@memprotocol" />
      <meta name="twitter:title" content={__title} />
      <meta
        name="twitter:description"
        content="Mem is building human-centric tools for social layer of web3. Own your friend graph, explore the blockchain, and earn by sharing knowledge."
      />
      <meta name="twitter:creator" content="@memprotocol" />
      <meta
        name="twitter:image"
        content="https://mem.co/mem_social_banner.png"
      />
    </Head>
  );
};

export default NextHead;
